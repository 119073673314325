/**
  * Reset some basic elements
*/
@font-face {
  font-family: 'NewYorkMedium';
  src: url("/assets/fonts/newyorkmedium-regular-webfont.woff") format('woff'),
       url("/assets/fonts/newyorkmedium-regular-webfont.woff2") format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NewYorkMedium';
  src: url("/assets/fonts/newyorkmedium-regularitalic-webfont.woff") format('woff'),
       url("/assets/fonts/newyorkmedium-regularitalic-webfont.woff2") format('woff2');
  font-weight:normal;
  font-style: italic;
}

@font-face {
  font-family: 'NewYorkMedium';
  src: url("/assets/fonts/newyorkmedium-bold-webfont.woff") format('woff'),
       url("/assets/fonts/newyorkmedium-bold-webfont.woff2") format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'NewYorkLarge';
  src: url("/assets/fonts/newyorklarge-regular-webfont.woff") format('woff'),
       url("/assets/fonts/newyorklarge-regular-webfont.woff2") format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NewYorkLarge';
  src: url("/assets/fonts/newyorklarge-regularitalic-webfont.woff") format('woff'),
       url("/assets/fonts/newyorklarge-regularitalic-webfont.woff2") format('woff2');
  font-weight:normal;
  font-style: italic;
}

@font-face {
  font-family: 'NewYorkLarge';
  src: url("/assets/fonts/newyorklarge-bold-webfont.woff") format('woff'),
       url("/assets/fonts/newyorklarge-bold-webfont.woff2") format('woff2');
  font-weight: bold;
  font-style: normal;
}

@media (prefers-color-scheme: light) {
  div {
    color: $color-body-text-light;
    background-color: $color-background-light;

  }
}

@media (prefers-color-scheme: dark) {
  div {
    color: $color-body-text-dark;
    background-color: $color-background-dark;
  }
}

* {
 box-sizing: border-box !important;
 margin: 0; }

input,
select,
textarea,
button {
 font-family: inherit;
 font-size: inherit;
 line-height: inherit;
}


body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
    font-family: NewYork;
}


/**
 * Basic styling
 */


body {
    font-family: $body-font-family;
    font-size: $body-font-size;
    font-style: normal;
    font-weight: normal;
    line-height: 1.5;
  /*  color: var(--text-color);
    background-color: var(--page-background-color); */
}




p {
  margin-top: 0;
  margin-bottom: 0.5em;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 1em;
  margin-bottom: 0.25em;
  font-family: $header-font-family
}

h1, .h1 { font-size: $h1-size; }
h2, .h2 { font-size: $h2-size; }
h3, .h3 { font-size: $h3-size; }
h4, .h4 { font-size: $h4-size; }
h5, .h5 { font-size: $h5-size; }
h6, .h6 { font-size: $h6-size; text-transform: uppercase; letter-spacing: 0.02em; }

a {
  color: $color-primary-link;
  text-decoration: none;
  padding-bottom: 1px;
  border-bottom: 1px solid currentColor;
}

a:hover {
  color: $color-link-hover;
  text-decoration: none;
  border-bottom: 1px dotted;
}
