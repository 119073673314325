@charset "utf-8";

// Import partials from `sass_dir` and set theme here
@import
        "theme-light.scss"
        // "theme-system.scss"
;
@font-face {
        font-family: NewYork;
        src: url("/assets/fonts/NewYorkMedium-Regular.otf")
      }
;
