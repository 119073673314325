
// Font family
$body-font-family: 'NewYorkMedium', system, -apple-system-body, BlinkMacSystemFont,;
$header-font-family: 'NewYorkLarge', -apple-system-headline, BlinkMacSystemFont, serif;
$body-font-size: 20px;
$prose-font-size: 20px;


// Type scale
$h0-mobile: 44px;
$h0-desktop: 44px;

$h1-size: 40px !default;
$h2-size: 36px !default;
$h3-size: 32px !default;
$h4-size: 28px !default;
$h5-size: 24px !default;
$h6-size: 20px !default;

// Container width
$container-width: 52em;

// Large breakpoint
$breakpoint-lg: 32em;

// Spacing unit
$spacer: 8px !default;

// Spacing scale
$spacer-1: $spacer !default; // 8px
$spacer-2: ($spacer * 2) !default; // 16px
$spacer-3: ($spacer * 4) !default; // 32px
$spacer-4: ($spacer * 8) !default; // 64px
